$primary: #1F3B4D !default;
$secondary: #6b7785 !default;
$success: #88c376 !default;
$info: #669BBC !default;
$warning: #dfc984 !default;
$danger: #C1121F !default;

$sidebar-widths: (
  sm: 12rem,
  lg: 20rem,
  xl: 40rem,
);
